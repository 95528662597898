<script>
import Api from '@/api/core/deliverManage';
import { cloneDeep, isEmpty, isEqual, pick } from 'lodash-es';

export default {
  name: 'Today',
  data: () => ({
    layout: {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    },
    formData: {
      sort: 1,
      size: 100,
      page: 1,
      advertiserName: undefined,
      operatorId: [],
    },

    columns: [
      {
        dataIndex: 'objectiveName',
        key: 'objectiveName',
        title: '指标',
        scopedSlots: { customRender: 'name' },
        customCell(record) {
          return {
            class: record.status === 1 ? 'p-table-td__red' : '',
          };
        },
      },
      {
        dataIndex: '',
        key: '',
        slots: { title: 'customTitle' },
        scopedSlots: { customRender: 'objective' },
      },
    ],
    data: [],
    total: 0,

    loading: false,

    visible: false,
    pitcherList: [],
  }),
  created() {
    try {
      const params = JSON.parse(sessionStorage.getItem('__Todday_params__') || '{}');
      console.log(params);
      if (!isEmpty(params)) {
        this.formData = { ...this.formData, ...pick(params, ['advertiserName', 'page', 'size', 'sort', 'operatorId']) };
      }
    } finally {
      console.log('finally-err');
    }
    this.$nextTick(this.init);
  },
  methods: {
    async init() {
      await this.getUsersList();
      await this.getList();
    },
    handleReset() {
      this.formData = cloneDeep(this.$options.data().formData);
      this.getList();
    },
    handleSearch() {
      this.formData.page = 1;
      this.formData.size = 100;
      this.getList();
    },

    async getList() {
      this.loading = true;

      const params = cloneDeep(this.formData);

      Api.getTodayList({
        ...params,
        operatorId: params.operatorId.join(','),
      })
        .then((res) => {
          sessionStorage.setItem('__Todday_params__', JSON.stringify(params));
          if (isEqual(res.code, 200)) {
            this.data = res.data.list || [];
            this.total = res.data.total || 0;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // 获取投手
    async getUsersList() {
      this.$api.core.pphtManage.getUsersList({ client_id: 'bfz', page_size: 1000 }).then((res) => {
        if (res.code === 200) {
          this.pitcherList = res.data.list;
        } else {
          this.$message.error(`获取投手列表失败，${res.message}`);
        }
      });
    },

    // 处理分页
    handlePaginationChange(current, pageSize) {
      this.formData.page = current;
      this.formData.size = pageSize;
      this.getList();
    },


    goToPage(advertiserName) {
      this.$router.push({
        path: '/advertisingOptimizationBoard',
        query: {
          advertiserName,
        },
      });
    },
  },
};
</script>

<template>
  <ACard class="p-yesterday">
    <APageHeader title="今日异常广告主" />

    <a-form-model ref="form" :model="formData" v-bind="layout">
      <a-row>
        <a-col :span="7">
          <a-form-model-item label="广告主">
            <a-input v-model="formData.advertiserName" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col :span="1"></a-col>
        <a-col :span="7">
          <a-form-model-item label="投手">
            <a-select
              v-model="formData.operatorId"
              placeholder="请选择"
              allow-clear
              show-search
              option-filter-prop="children"
              style="width: 100%"
              mode="multiple"
            >
              <a-select-option v-for="item in pitcherList" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col class="flex-end">
          <a-space>
            <base-button :title="'重置'" @onClickBtn="handleReset"></base-button>
            <base-button :type="'primary'" :title="'查询'" @onClickBtn="handleSearch"></base-button>
          </a-space>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="7">
          <a-form-model-item label="排序">
            <base-button
              :type="formData.sort == 1 ? 'primary' : ''"
              title="低消耗优先"
              @onClickBtn="
                formData.sort = formData.sort == 0 ? 1 : 0;
                handleSearch();
              "
            />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>

    <ASpin v-if="loading" class="p-loading" />

    <ARow :gutter="[10, 10]" style="width: 100%" type="flex">
      <ACol :span="6" :lg="6" :xl="6" :xxl="6" v-for="(item, index) of data" :key="item.advertiserId + `---${index}`">
        <ACard class="p-card">
          <ASpace direction="vertical" :size="10">
            <ARow>
              <ACol :span="12">目标编码： {{ item.code }}</ACol>
              <ACol :span="12">{{ item.advertiserName }}</ACol>
            </ARow>

            <ARow>
              <ACol :span="24">{{ item.operatorName }}</ACol>
            </ARow>
            <ATable
              class="p-table"
              :columns="columns"
              :data-source="item.list"
              bordered
              :scroll="{ x: false, y: 300 }"
              :pagination="false"
            >
              <div slot="name" slot-scope="text">{{ text }}</div>
              <div slot="objective" slot-scope="row">{{ row.total || 0 }} / {{ row.objective || 0 }}</div>

              <span slot="customTitle"
                >实时/实时目标 <AButton icon="question-circle" type="link" @click="visible = true"
              /></span>
            </ATable>

            <div class="p-card__footer">
              <BaseButton type="link" title="查看巨量广告优化建议 >"  @onClickBtn="goToPage(item.advertiserName)" />
            </div>
          </ASpace>
        </ACard>
      </ACol>
    </ARow>

    <div class="p-empty" v-if="!data.length">
      <AEmpty />
    </div>

    <AModal
      title="实时目标跟时段完成进度占比"
      :visible="visible"
      width="60%"
      centered
      :footer="null"
      @cancel="visible = false"
    >
      <div class="img-box">
        <img src="@/assets/images/target_map.jpg" />
      </div>
    </AModal>
  </ACard>
</template>

<style scoped lang="less">
/deep/ .ant-page-header {
  padding-left: 0;
  padding-right: 0;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 40px;
}

.p-card {
  font-size: 12px;
  /deep/ .ant-space.ant-space-vertical {
    width: 100%;
  }

  /deep/ .ant-table-fixed-header .ant-table-scroll .ant-table-header {
    overflow: auto;
  }

  /deep/ .ant-table-column-title .ant-btn-link {
    color: #333;
  }

  /deep/ .ant-card-body {
    padding: 0 16px 8px;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.p-modal {
  &__space {
    width: 100%;
  }
}

.p-yesterday {
  position: relative;
}

.p-loading {
  background: rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-empty {
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-box {
  width: 100%;
  height: 520px;

  img {
    width: 100%;
    height: 100%;
  }
}

.p-table {
  /deep/ .ant-table-tbody > tr > td.p-table-td__red {
    background: #ff0000;
  }

  /deep/ .ant-table-thead > tr > th,
  /deep/ .ant-table-tbody > tr > td {
    padding: 4px 6px;
    font-size: 12px;
  }
}
</style>
