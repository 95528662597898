import { render, staticRenderFns } from "./Today.vue?vue&type=template&id=84065cde&scoped=true&"
import script from "./Today.vue?vue&type=script&lang=js&"
export * from "./Today.vue?vue&type=script&lang=js&"
import style0 from "./Today.vue?vue&type=style&index=0&id=84065cde&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84065cde",
  null
  
)

export default component.exports